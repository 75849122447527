<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="4">
          <v-menu
            v-model="menuStartDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="startDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="menuStartDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-menu
            v-model="menuEndDate"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                outlined
                flat
                dense
                background-color="white"
                v-model="endDate"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="endDate" @input="menuEndDate = false"></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="4">
          <v-btn color="primary" @click="getReports">
            <v-icon>
              mdi-magnify
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="6" v-for="data in reports" :key="data.formNumber">
      <v-row>
        <v-col cols="6">Name</v-col>
        <v-col cols="6">{{ data.vendorName }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Form Number</v-col>
        <v-col cols="6">{{ data.formNumber }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Date</v-col>
        <v-col cols="6">{{ data.date }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Type</v-col>
        <v-col cols="6">{{ data.type }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">Description</v-col>
        <v-col cols="6">{{ data.description }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="headers"
            :items="data.accounts"
            sort-by="no"
            dense
            hide-default-footer
          >
            <template v-slot:item.no="{ item }">
              {{ data.accounts.map(x => x).indexOf(item) + 1 }}
            </template>
            <template v-slot:item.debit="{ item }">
              {{ formatPrice(item.debit) }}
            </template>
            <template v-slot:item.credit="{ item }">
              {{ formatPrice(item.credit) }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";

export default {
  data: () => ({
    startDate: moment()
      .clone()
      .startOf("month")
      .format("yyyy-MM-DD"),
    endDate: moment()
      .clone()
      .endOf("month")
      .format("yyyy-MM-DD"),
    menuStartDate: false,
    menuEndDate: false,
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Account Number",
        value: "accountNumber",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Account Name",
        value: "accountName",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Debit",
        value: "debit",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Credit",
        value: "credit",
        sortable: false,
        align: "center",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),
  computed: {
    ...mapState("report", ["reports"]),
  },
  created() {
    this.getReports();
  },
  methods: {
    getReports() {
      this.$store.dispatch("report/getAllJournal", {
        startDate: this.startDate,
        endDate: this.endDate,
      });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
};
</script>

<style></style>
